.pricing-container {
  padding-top: 48px;
  padding-bottom: 48px;
  background: url("./assets/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.pricing-container .title {
  font-size: 48px;
  color: #012345;
  text-shadow: 0px 4px 7px rgba(3,57,108,0.2);
  font-weight: 500;
  border-bottom: 5px solid #1dd8e4;
  display: inline-block;
  padding-bottom: 10px;
}
.pricing-container .description {
  font-size: 26px;
  text-align: center;
  color: #012345;
  max-width: 984px;
}
/*# sourceMappingURL=src/components/pages/pricing/PricingPage.css.map */