






































































































































































@import '~@/styles/variables'

.btn.btn-crypitor
  &:hover
    box-shadow 0px 5px 4px rgba(0, 0, 0, 0.25)

.price-tag
  font-weight 500
  font-size 26px

.cross
  opacity 0.2

.split
  border-top 0.0625rem solid blue-cornflower
  border-bottom 0.0625rem solid blue-cornflower

.flex-basis-50
  flex-basis 50%

@media mobile
  table
    display block

    thead, thead tr, tbody, tbody tr, tbody tr td
      display block

    thead td:not(:first-child)
      display block
      box-shadow 0px 3px 12px rgba(40, 62, 80, 0.15)
      margin-bottom 25px
      border-radius 4px
      background white
      padding 25px

    tbody tr
      border 1px solid #DFE1E5
      border-radius 4px
      margin-bottom 25px

    tbody tr td:not(:last-child)
      border-bottom 1px solid #DFE1E5

    tbody tr td:first-child
      background #F4F5F7

table
  td:first-child
    width 23%

  @media mobile
    td:first-child
      width 100%

  td
    padding 15px
    font-size 16px
    position relative

  td.equilibrium
    &::after
      content ''
      position absolute
      z-index 0
      top 45%
      left 50%
      width 2px
      height 20px
      background blue-link-water
      transform skew(55deg)

    .sup
      display block
      position relative
      width 50%
      float left
      padding-top 8%
      line-height 100%
      text-align center
      z-index 1

    .inf
      display block
      position relative
      width 50%
      float left
      padding-top 15%
      line-height 100%
      z-index 1

  thead td
    color blue-cornflower

.head-1
  color blue-cornflower
  font-size 18px
  font-weight 500

.head-2
  color blue-cornflower
  font-size 24px
  font-weight 500
  font-weight bold

.head-3
  color blue-cornflower
  font-size 16px
  font-weight 500
