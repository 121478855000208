.masthead {
  min-height: calc(100vh - 70px);
  color: #fff;
  background: url("../assets/masthead.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .masthead {
    background: rgba(3,57,108,0.8);
  }
}
.masthead > .container {
  height: 70%;
}
.title {
  font-size: 39px;
  font-weight: 500;
  text-shadow: 0px 5px 7px rgba(0,0,0,0.25);
  margin-bottom: 42px;
  background: url("../assets/splash.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 294px;
}
.description {
  font-size: 24px;
}
/*# sourceMappingURL=src/components/pages/pricing/components/MastHead.css.map */