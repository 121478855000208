.btn.btn-crypitor:hover {
  box-shadow: 0px 5px 4px rgba(0,0,0,0.25);
}
.price-tag {
  font-weight: 500;
  font-size: 26px;
}
.cross {
  opacity: 0.2;
}
.split {
  border-top: 0.0625rem solid #012345;
  border-bottom: 0.0625rem solid #012345;
}
.flex-basis-50 {
  flex-basis: 50%;
}
@media only screen and (max-width: 767px) {
  table {
    display: block;
  }
  table thead,
  table thead tr,
  table tbody,
  table tbody tr,
  table tbody tr td {
    display: block;
  }
  table thead td:not(:first-child) {
    display: block;
    box-shadow: 0px 3px 12px rgba(40,62,80,0.15);
    margin-bottom: 25px;
    border-radius: 4px;
    background: #fff;
    padding: 25px;
  }
  table tbody tr {
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    margin-bottom: 25px;
  }
  table tbody tr td:not(:last-child) {
    border-bottom: 1px solid #dfe1e5;
  }
  table tbody tr td:first-child {
    background: #f4f5f7;
  }
}
table td:first-child {
  width: 23%;
}
@media only screen and (max-width: 767px) {
  table td:first-child {
    width: 100%;
  }
}
table td {
  padding: 15px;
  font-size: 16px;
  position: relative;
}
table td.equilibrium::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 45%;
  left: 50%;
  width: 2px;
  height: 20px;
  background: #c5dbf2;
  transform: skew(55deg);
}
table td.equilibrium .sup {
  display: block;
  position: relative;
  width: 50%;
  float: left;
  padding-top: 8%;
  line-height: 100%;
  text-align: center;
  z-index: 1;
}
table td.equilibrium .inf {
  display: block;
  position: relative;
  width: 50%;
  float: left;
  padding-top: 15%;
  line-height: 100%;
  z-index: 1;
}
table thead td {
  color: #012345;
}
.head-1 {
  color: #012345;
  font-size: 18px;
  font-weight: 500;
}
.head-2 {
  color: #012345;
  font-size: 24px;
  font-weight: 500;
  font-weight: bold;
}
.head-3 {
  color: #012345;
  font-size: 16px;
  font-weight: 500;
}
/*# sourceMappingURL=src/components/pages/pricing/components/PricingTable.css.map */