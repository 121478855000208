



















@import '~@/styles/variables'

bg-url = './assets/bg.svg'

.pricing-container
  padding-top 48px
  padding-bottom @padding-top
  background url(bg-url)
  background-size cover
  background-repeat no-repeat
  min-height 100vh

  .title
    font-size 48px
    color blue-cornflower
    text-shadow 0px 4px 7px rgba(3, 57, 108, 0.2)
    font-weight 500
    border-bottom 5px solid blue-java
    display inline-block
    padding-bottom 10px

  .description
    // font-weight bold
    font-size 26px
    text-align center
    color blue-cornflower
    max-width 984px
